import TestCanvas from "./canvases/TestCanvas";

function App() {
  return (
    <div className="App">
      <div style={{ height: 400, width: 800 }}>
        <TestCanvas />
      </div>
    </div>
  );
}

export default App;
