import { useCallback } from "react";
import Canvas from "../components/Canvas";
import { useMousePosition } from "../hooks/useMousePosition";

const N = 30;

const TestCanvas = () => {
  const mousePosition = useMousePosition();
  const yCanvas = window.innerHeight;
  const xCanvas = window.innerWidth;

  const draw = useCallback(
    (ctx) => {
      const { canvas } = ctx;
      const { offsetTop, offsetLeft } = canvas;
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const xMouse = mousePosition.x - offsetLeft;
      const yMouse = mousePosition.y - offsetTop;

      ctx.fillStyle = "#000000";

      for (let x = 0; x < N; x++) {
        for (let y = 0; y < N; y++) {
          ctx.save();
          const xDiff = (xCanvas / N) * (x + 0.5);
          const yDiff = 15 + (yCanvas / N) * y;

          const frac = (yMouse - yDiff) / (xMouse - xDiff);
          let angle = Math.atan(frac);
          if (xMouse >= xDiff) angle = angle + Math.PI;

          const distance = Math.sqrt(
            Math.pow(yMouse - yDiff, 2) + Math.pow(xMouse - xDiff, 2)
          );

          ctx.translate(xDiff, yDiff);
          ctx.rotate(0.5 * Math.PI + angle);
          ctx.fillRect(0, 0, distance * 0.05, distance * 0.05);
          ctx.restore();
        }
      }
    },
    [mousePosition.x, mousePosition.y, xCanvas, yCanvas]
  );

  return <Canvas height={yCanvas} width={xCanvas} draw={draw} />;
};

export default TestCanvas;
